<template>
  <div class="app flex-row align-items-center guest">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col xs="12" md="8" lg="6">
          <b-card-group>
            <b-card no-body class="p-3">
              <b-card-body>
                <form @submit.prevent="validateBeforeSubmit">
                  <img class="mx-auto d-block mb-4 navbar-brand-full" src="img/brand/invoice-logo-2019.png" height="95"
                    alt="RTNEST Inc. Logo">
                  <h1>Forgot Password </h1>
                  <p class="text-muted">Please enter your Email</p>
                  <flash-message autoHide variant="success"></flash-message>
                  <b-input-group class="mb-2">
                    <b-input-group-prepend>
                      <b-input-group-text><i class="fa fa-envelope"></i></b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input type="text" class="form-control" name="email" v-model="email"
                      v-validate="'required|email'" placeholder="Email" autocomplete="username email" />
                  </b-input-group>
                  <i v-show="errors.has('email')" class="fa fa-exclamation-triangle mb-3"></i> <span
                    v-show="errors.has('email')" class="mb-3 help is-danger">{{ errors.first('email') }}</span>

                  <b-input-group class="mb-2">
                    <b-input-group-prepend style="z-index: 99;">
                      <b-input-group-text><i class="fa fa-cog"></i></b-input-group-text>
                    </b-input-group-prepend>
                    <select class="select-account form-control" name="account" v-validate="'required'"
                      v-model="account">
                      <option value="" disabled selected>Account Type</option>
                      <option for v-for="option in accounts" v-bind:value="option.url">{{ option.accountType }}</option>
                    </select>
                    <i class="dropdown-icon fa fa-chevron-down position-absolute"></i>
                  </b-input-group>

                  <i v-show="errors.has('account')" class="fa fa-exclamation-triangle"></i> <span
                    v-show="errors.has('account')" class="mb-3 help is-danger">The account type is required.</span>

                  <input type="hidden" name="robot" v-model="robot" v-validate="'required'" />

                  <i v-show="signInError" class="fa fa-exclamation-triangle"></i><span v-show="signInError"
                    class="help is-danger">Reset failed. Please try again or contact support.</span>

                  <b-input-group class="cap">
                    <vue-recaptcha ref="reCaptcha" @verify="onCaptchaVerified" @expired="onCaptchaExpired" class="mt-2"
                      sitekey="6LfAwYcUAAAAACZe3pwLB7otQzENyRr_UlZshjx6">
                    </vue-recaptcha>
                  </b-input-group>
                  <i v-show="errors.has('robot')" class="fa fa-exclamation-triangle mt-2 mb-2"></i> <span
                    v-show="errors.has('robot')" class="mb-3 help is-danger">The reCaptcha is required.</span>

                  <b-row class="mt-3">
                    <b-col cols="12" class="col-md-5">
                      <b-button variant="primary" type="submit" class="w-100">Reset Password</b-button>
                    </b-col>
                  </b-row>
                </form>
              </b-card-body>
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';

export default {
  name: 'Forgot',
  components: {
    VueRecaptcha
  },
  data: function () {
    return {
      signInError: false,
      email: '',
      account: '',
      robot: '',
      accounts: [
        {
          url: "accountant",
          accountType: "Accountant"
        },
        {
          url: "manager",
          accountType: "Manager"
        },
        {
          url: "wts",
          accountType: "Project Manager"
        },
        {
          url: "sys_admin",
          accountType: "System Admin"
        },
        {
          url: "vendor",
          accountType: "Vendor"
        },
        {
          url: "wss",
          accountType: "Well Site Supervisor"
        },
      ]
    }
  },
  methods: {
    onCaptchaExpired: function () {
      this.robot = '';
    },
    onCaptchaVerified: function (recaptchaToken) {
      this.robot = 'true';
    },
    validateBeforeSubmit() {
      this.robot = 'true';
      this.$validator.validateAll().then((result) => {
        if (result) {
          let data = JSON.stringify({
            email: this.email,
          })
          let url = this.account;
          let redirect = "";
          if (url === 'accountant') {
            url = "manager";
            redirect = "accountant";
          }
          if (url === 'wts') {
            url = "wts";
            redirect = "pm";
          } else {
            redirect = this.account;
          }
          this.$axios.post("/" + url + '/reset/password', data)
            .then(response => {
              this.flash({ message: 'Reset Successful. Please check email for reset password link.', variant: 'success' });
              this.$router.push("/")
            })
            .catch(error => {
              this.signInError = true;
              this.robot = '';
              this.$refs.reCaptcha.reset();
            })
          return;
        }
      });
    },
  }
}
</script>

<style lang="scss">
.select-account {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-left: 10px;
}

.fa-exclamation-triangle {
  margin-right: 7px;
}

.dropdown-icon {
  right: 15px;
  top: 10px;
  z-index: 99;
}
</style>
